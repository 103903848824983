import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"
import CloseImg from "./close.svg"
import enhanceWithClickOutside from "react-click-outside"

class Navigation extends React.Component {
  handleClickOutside = () => {
    if (!this.props.isCollapsed) {
      this.props.toggleMenu()
    }
  }

  render() {
    const { categories } = this.props

    return (
      <nav className={`sidemenu${this.props.isCollapsed ? " hidden" : ""}`}>
        <button className="close-button" onClick={this.props.toggleMenu}>
          <img src={CloseImg} alt="Dölj meny" />
        </button>
        <h3>Kategorier</h3>
        <ul>
          {categories.map((category, i) => (
            <li key={i}>
              <Link to={`/${category.slug}`} className="underlined">{category.name}</Link>
            </li>
          ))}
        </ul>
        <hr />
        <ul>
          <li>
            <Link to="/cookies" className="underlined">Cookies</Link>
          </li>
          <li>
            <Link to="/integritetspolicy" className="underlined">Integritetspolicy</Link>
          </li>
          <li>
            <Link to="/friskrivning-och-partnerskap" className="underlined">
              Friskrivning och partnerskap
            </Link>
          </li>
          <li>
            <Link to="/kontakt" className="underlined">Kontakt</Link>
          </li>
          <li>
            <Link to="/om-oss" className="underlined">Om oss</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

Navigation.propTypes = {
  categories: PropTypes.array,
  toggleMenu: PropTypes.func,
}

Navigation.defaultProps = {
  categories: [],
}

export default enhanceWithClickOutside(Navigation)
