import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      imageSharp(fixed: { originalName: { eq: "toppicks-logo.png" } }) {
        id
        fixed(quality: 100, width: 70) {
          src
        }
      }
      allContentfulCategory(sort: {fields: name}) {
        nodes {
          slug
          name
        }
      }
    }
  `)

  return (
    <div className="layout">
      <Header
        siteTitle={data.site.siteMetadata.title}
        logoSrc={data.imageSharp.fixed.src}
        categories={data.allContentfulCategory.nodes}
      />
      <main className="container content-container">{children}</main>
      <Footer
        logoSrc={data.imageSharp.fixed.src}
        siteTitle={data.site.siteMetadata.title}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
