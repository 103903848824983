import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"
import Helmet from "react-helmet"
import Navigation from "../Navigation"
import BurgerImg from "./burger.svg"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isCollapsed: true }
  }

  toggleMenu = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }

  render() {
    const { siteTitle, logoSrc } = this.props
    return (
      <>
        <header id="header">
          <Helmet
            script={[
              {
                innerHTML: `
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function() {
              var currentScrollPos = window.pageYOffset;
              if (prevScrollpos > currentScrollPos) {
                document.getElementById("header").style.transform = "unset";
              } else if (currentScrollPos > 16) {
                document.getElementById("header").style.transform = "translateY(-100%)";
              }
              prevScrollpos = currentScrollPos;
            }
        `,
                type: "text/javascript",
              },
            ]}
          />
          <div className="container">
            <Link to="/">
              <img className="logo" src={logoSrc} alt={siteTitle} />
            </Link>
            <button className="menu" onClick={this.toggleMenu}>
              <img src={BurgerImg} alt="Visa meny" />
            </button>
          </div>
        </header>
        <Navigation
          categories={this.props.categories}
          isCollapsed={this.state.isCollapsed}
          toggleMenu={this.toggleMenu}
        />
      </>
    )
  }
}

Header.propTypes = {
  categories: PropTypes.array,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  categories: [],
  siteTitle: ``,
}

export default Header
