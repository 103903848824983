import React from "react"
import { Link } from "gatsby"
import "./styles.scss"

const Footer = ({ logoSrc, siteTitle }) => (
  <footer>
    <div className="container footer-links-wrapper">
      <ul className="footer-links">
        <li>
          <Link to="/cookies">Cookies</Link>
        </li>
        <li>
          <Link to="/integritetspolicy">Integritetspolicy</Link>
        </li>
        <li>
          <Link to="/friskrivning-och-partnerskap">
            Friskrivning och partnerskap
          </Link>
        </li>
        <li>
          <Link to="/kontakt">Kontakt</Link>
        </li>
        <li>
          <Link to="/om-oss">Om oss</Link>
        </li>
      </ul>
    </div>
    <div className="container">
      <Link to="/">
        <img src={logoSrc} alt="TopPicks logga" />
      </Link>
      <span className="footer-right">
        © {new Date().getFullYear()} <Link to="/">{siteTitle}</Link>
      </span>
    </div>
  </footer>
)

export default Footer
